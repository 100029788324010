import React from 'react';
import maps from '../assets/map.png'; // Ajuste o caminho conforme necessário

const About = () => {

    return (
        <div className="bg-white text-black py-8">
            <div className="max-w-2xl mx-auto">
                <h1 className="font-lalezar text-3xl font-bold text-center mb-4">Our Service Area</h1>
                <div className="flex flex-col md:flex-row items-center justify-center">

                    <div className="md:flex-1">
                        <img src={maps} alt="Fence" className="w-full h-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
// components/QuoteForm.jsx
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import backgroundImage from '../assets/banner.jpg';

const QuoteForm = () => {
    const form = useRef();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        cel: '',
        email: '',
        city: '',
        material: '',
        source: '',
        notes: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const isFormValid = () => {
        return Object.values(formData).every(value => value.trim() !== '');
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mcwvkr6', 'template_lsk8h2u', form.current, 'eOFNi2ZjSMNTe5e9C')
            .then((result) => {
                console.log(result.text);
                alert("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                alert("Failed to send the message, please try again.");
            });
    };
    return (
        <div className=' bg-cover bg-center py-[20px]' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="max-w-2xl mx-auto my-0 rounded-t-md overflow-hidden shadow-lg">
                <div className="font-lalezar bg-green-700 text-white text-3xl font-bold py-4 text-center">
                    GET A QUOTE
                </div>
                <div className="font-krub bg-white bg-opacity-90 p-8">
                    <form ref={form} onSubmit={sendEmail} onChange={handleInputChange} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <input name="firstName" type="text" placeholder="First Name" className="border border-gray-400 rounded p-2 focus:outline-none focus:border-green-600 focus:border-2" required />
                            <input name="lastName" type="text" placeholder="Last Name" className="border border-gray-400 rounded p-2 focus:outline-none focus:border-green-600 focus:border-2" required />
                            <input name="cel" type="tel" placeholder="Phone Contact" className="border border-gray-400 rounded p-2 focus:outline-none focus:border-green-600 focus:border-2" required />
                            <input name="email" type="email" placeholder="Email" className="border border-gray-400 rounded p-2 focus:outline-none focus:border-green-600 focus:border-2" required />
                            <input name="city" type="text" placeholder="Location/City" className="border border-gray-400 rounded p-2 focus:outline-none focus:border-green-600 focus:border-2" required />
                            <select name="material" className="border border-gray-400 rounded p-2 focus:border-2 focus:outline-none focus:border-green-600" required>
                                <option>Material</option>
                                <option>Ornamental Aluminum</option>
                                <option>Wood Fence</option>
                                <option>Vinyl Fence</option>
                                <option>Chain Link Fence</option>
                                <option>Repairs</option>
                                <option>Arbors and Pergolas</option>
                            </select>
                        </div>
                        <select name="source" className="border border-gray-400 rounded p-2 focus:border-2 focus:outline-none focus:border-green-600 p-2 w-full" required>
                            <option>How Did You Hear About Us?</option>
                            <option>Thumbtack</option>
                            <option>Angi</option>
                            <option>Google</option>
                            <option>Yelp</option>
                            <option>Referral</option>
                            <option>Friends/Family</option>
                            <option>Other</option>
                            {/* Add additional options here */}
                        </select>
                        <textarea name="notes" required placeholder="Additional Notes About Your Project:" rows="4" className="border border-gray-400 rounded p-2 focus:border-2 focus:outline-none focus:border-green-600 w-full resize-none"></textarea>
                        <button
                            type="submit"
                            disabled={!isFormValid()}
                            className={`w-full bg-green-500 text-white font-bold py-2 rounded transition-colors duration-300 ${isFormValid() ? 'hover:bg-green-600' : 'opacity-50 cursor-not-allowed'
                                }`}
                        >
                            SUBMIT
                        </button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default QuoteForm;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import QuoteForm from './components/QuoteForm';
import About from './components/About';
import Footer from './components/Footer';
import Map from './components/Map';

function App() {
  return (
    <Router>
      <div className="App min-h-screen">
        <div className="mx-auto" style={{}}>
          <Header />
          <Hero />
          <Services />
          <QuoteForm />
          <About />
          <Map />
          <Footer />
          <Routes>
            <Route path="/">
              {/* <Home /> */}
            </Route>
            <Route path="/services">
              {/* <Services /> */}
            </Route>
            <Route path="/about">
              {/* <About /> */}
            </Route>
            <Route path="/quote">
              {/* <Quote /> */}
            </Route>
            {/* Add more routes as needed */}
          </Routes>
        </div></div>

    </Router>
  );
}
export default App;
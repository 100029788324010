import React from 'react';
import backgroundImage from '../assets/iStock-1349364325.jpg';
import logo from '../assets/logo.png';

const Hero = () => {
    return (
        <div id="/" className="relative text-black text-center bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="bg-black bg-opacity-20 flex flex-col justify-center items-center" style={{ height: '35em' }}> {/* Adjust height as needed */}
                <img src={logo} alt="logo" className="w-full max-w-xs md:max-w-sm lg:max-w-lg" />
            </div>
        </div>
    );
};

export default Hero;
import React from 'react';
import fenceImage from '../assets/iStock-185278747.jpg'; // Ajuste o caminho conforme necessário

const About = () => {

    return (
        <div className="bg-black text-white py-8">
            <div className="max-w-2xl mx-auto">
                <h1 className="font-lalezar text-3xl font-bold text-center mb-4">About us</h1>
                <div className="flex flex-col md:flex-row items-center justify-center">
                    <div className="md:flex-1 p-4">
                        <p className="font-krub     text-sm">
                            Our fence business offers more than just barriers; we provide peace of mind and elevate
                            property aesthetics. With a focus on security, privacy, and style, we specialize in expert
                            installations using premium materials tailored to each client’s needs. From start to finish,
                            we handle every aspect, ensuring compliance with local regulations and delivering results
                            that enhance property value. Choose us for quality craftsmanship and unmatched customer
                            satisfaction. Elevate your property with our fence solutions today.
                        </p>
                    </div>
                    <div className="md:flex-1">
                        <img src={fenceImage} alt="Fence" className="w-full h-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
import React from 'react';
import { FaFacebook, FaInstagram, FaYelp } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';

const Footer = () => {

    const currentYear = new Date().getFullYear();
    return (
        <footer className="font-krub bg-black text-white py-4">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-8 py-2">
                <div className="flex items-center mb-2 md:mb-0">
                    <MdEmail className="text-2xl mr-2" />
                    <span className="text-sm md:text-base">northfencema@gmail.com</span>
                </div>
                <div className="flex items-center mb-2 md:mb-0">
                    <MdPhone className="text-2xl mr-2" />
                    <span className="text-sm md:text-base">(508) 405-9009</span>
                </div>
                <div className="flex items-center">
                    <span className="text-sm md:text-base mr-2">Social</span>
                    <FaFacebook className="text-2xl mr-2" />
                    <FaInstagram className="text-2xl mr-2" />
                    <FaYelp className="text-2xl" />
                </div>
            </div>
            <div className="text-center text-gray-400 text-xs md:text-sm py-2">
                COPYRIGHT {currentYear} , THE NORTH FENCE | ALL RIGHTS RESERVED
            </div>
        </footer>
    );
};

export default Footer;

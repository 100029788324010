// components/Header.jsx
import React from 'react';
import Slider from 'react-slick';
import Aluminum from '../assets/Aluminum.jpg';
import Chain_link from '../assets/Chain_link.jpg';
import Pergola_and_Arbor from '../assets/Pergola_and_Arbor.jpg';
import Vinyl from '../assets/Vinyl.jpg';
import Wood from '../assets/Wood.jpg';
import Repairs from '../assets/Repairs.jpg';

const Services = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 10000,
        responsive: [
            {
                breakpoint: 1024, // Para telas menores que 1024px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600, // Para telas menores que 600px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480, // Para telas menores que 480px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const items = [
        { name: "Ornamental Aluminum", img: Aluminum },
        { name: "Wood Fence", img: Wood },
        { name: "Vinyl Fence", img: Vinyl },
        { name: "Chain Link Fence", img: Chain_link },
        { name: "Repairs", img: Repairs },
        { name: "Arbors and Pergolas", img: Pergola_and_Arbor },
    ];

    return (
        <div id="services" className="font-bold bg-white py-8">
            <p className="font-lalezar text-4xl text-center">Services</p>
            <Slider {...settings}>
                {items.map((item, index) => (
                    <div key={index} className="font-krub px-4">
                        <h3 className="text-center text-black text-sm mt-2 pb-2">{item.name}</h3>
                        <img src={item.img} alt={item.name} className="w-full h-[15em]" />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Services;
